import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
// import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import Servicios from "../img/servicios.png";
import CardActionArea from "@material-ui/core/CardActionArea";
import TouchAppIcon from '@material-ui/icons/TouchApp';
import {
  Link
} from "react-router-dom";
// const useStyles = makeStyles({
//   pos: {
//     marginBottom: 12,
//   },
// });

export default function SimpleCard() {
  // const classes = useStyles();
  // // const bull = <span className={classes.bullet}>•</span>;

  return (
    <div className="animate__animated animate__fadeInUp">
    <Grid container>
      <Card>
        <CardActionArea>
        <Link to="/servicios" style={{ color: 'inherit', textDecoration: 'inherit'}}>
          <Grid container>
            <Grid xs={5}>
              <CardContent>
                <Typography variant="h6" component="h5" >
                  Servicios
                </Typography>
                <Typography variant="subtitle2" color="textSecondary" align="center">
                  más +
                </Typography>
                
                <center><TouchAppIcon 
                fontSize="large"
                color="disabled"
                /></center>
              </CardContent>
            </Grid>
            <Grid xs={7}>
              <CardMedia
                component="img"
                alt="internet san anotino de los vazquez"
                height="100%"
                image={Servicios}
                title="internet cuquio"
              />
            </Grid>
          </Grid>
          </Link>
        </CardActionArea>
      </Card>
      <Grid xs>
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          Contamos con una amplia variedad de servicios, como: <strong>cctv, alarmas, telefonía ip/análoga y servicos TI.</strong>
        </Typography>
      </CardContent>
      </Grid>
    </Grid>
    </div>
  );
}
