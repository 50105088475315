import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import Typography from '@material-ui/core/Typography';
import Iframe from 'react-iframe';

const useStyles = makeStyles({
  root: {
    minWidth: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard() {
  const classes = useStyles();


  return (
    <div className="animate__animated animate__fadeInUp">
    <Card className={classes.root} >
      <CardContent>
        <Iframe
             url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d930.0950861312816!2d-102.88405117081189!3d21.177044599119885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428fcb1a3ef4c99%3A0x2ae0ecdb2eb14f6d!2sBenito%20Ju%C3%A1rez%20160%2C%20La%20Ermita%2C%2047300%20Yahualica%20de%20Gonz%C3%A1lez%20Gallo%2C%20Jal.!5e0!3m2!1ses-419!2smx!4v1610658589482!5m2!1ses-419!2smx"
             width="100%"
             height="450"
             frameBorder ="0"
             allow="fullscreen"
        />
      </CardContent>
    </Card>
    </div>
  );
}
