import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
// import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import Basico from "../img/basico.png";
import CardActionArea from "@material-ui/core/CardActionArea";
import TouchAppIcon from '@material-ui/icons/TouchApp';
//Modal
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// const useStyles = makeStyles({
//   pos: {
//     marginBottom: 12,
//   },
// });

export default function SimpleCard() {
  
  // funcion modal
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

  };

  return (
    <div className="animate__animated animate__fadeInUp">
    <Grid container>
      <Card>
      {/* accional el modal */}
        <CardActionArea variant="outlined" color="primary" onClick={handleClickOpen} >
          <Grid container>
            <Grid xs={5}>
              <CardContent>
                <Typography variant="h6" component="h5">
                  Básico
                </Typography>
                <Typography variant="subtitle2" color="textSecondary" align="center">
                  Social
                </Typography>
                
                <center><TouchAppIcon 
                fontSize="large"
                color="disabled"
                /></center>
              </CardContent>
            </Grid>
            <Grid xs={7}>
              <CardMedia
                component="img"
                alt="internet terabyte"
                height="100%"
                image={Basico}
                title="terabyte internet"
              />
            </Grid>
          </Grid>
        </CardActionArea>

        {/* Modal*/}
        <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Te explicamos que tiene el paquete básico"}</DialogTitle>
        <DialogContent>
        <CardMedia
                component="img"
                alt="internet ixtlahuacan del rio"
                height="150"
                image={Basico}
                title="internet en cuquio"
              />
          <DialogContentText>
          <Typography variant="body2" color="textSecondary" component="p">
          <strong>El paquete básico</strong> es perfecto para usar las <strong>redes sociales, chatear, </strong> contactarte con tus amigos
          por <strong>WhatsApp, Messenger</strong> etc... 
          <blockquote>
            Los costos y velocidades pueden variar según zona,verifica los precios con tu ejecutivo/a.
          </blockquote>
        </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/* Terminacion del modal */}
      </Card>
      <Grid xs>
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          <strong>El paquete básico</strong> es perfecto para usar las <strong>redes sociales, chatear, </strong> contactarte con tus amigos
          por <strong>WhatsApp, Messenger</strong> etc... 
        </Typography>
      </CardContent>
      </Grid>
    </Grid>
    </div>
  );
}


