import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardContent from '@material-ui/core/CardContent';
import VideocamIcon from '@material-ui/icons/Videocam';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledAccordions() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}><VideocamIcon color='secondary'/></Typography>
          <Typography className={classes.secondaryHeading}>Cámaras de seguridad</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          Contamos con una amplia experiencia en videovigilancia, <strong>somos expertos en industria y residencial</strong>, manejamos tecnologías tanto <strong>análogas e ip.</strong> 
        </Typography>
      </CardContent>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}><PhoneInTalkIcon color="secondary" /></Typography>
          <Typography className={classes.secondaryHeading}>
            Tecnología análoga e ip
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          La comunicación hoy en día no es un lujo es una necesidad por eso nosotros contamos con: <strong>instalación y configuración</strong> de equipos con tecnologías <strong>análogas e ip.</strong> 
        </Typography>
      </CardContent>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}><NotificationsActiveIcon color='secondary'/></Typography>
          <Typography className={classes.secondaryHeading}>
            Industrial y residencial
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          Esta comprobado que las alarmas son eficientes para ahuyentar cualquier tipo de amenaza por eso contantamos con  <strong>instalación y configuración</strong> de éstos equipos. 
        </Typography>
      </CardContent>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}><SettingsInputAntennaIcon color='secondary' /></Typography>
          <Typography className={classes.secondaryHeading}>
            Enlaces profesionales
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          La comunicación hoy en día no es un lujo es una necesidad por eso nosotros contamos con: <strong>instalación y configuración</strong> de equipos con tecnologías <strong>análogas e ip.</strong> 
        </Typography>
      </CardContent>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
