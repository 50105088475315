import React from "react";
import Grid from "@material-ui/core/Grid";
import ImgMediaCard from "../cards/tarjetaUno";
import SimpleCard from "../cards/tarjetaDos";
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
//paginas
import Basico from "../cards/basico";
import Avanzado from "../cards/avanzado";
import Savanzado from "../cards/savanzado";
import Servicios from "../cards/servicios";


const inicio = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <ImgMediaCard/>
        </Grid>
        <Grid item xs={12} lg={4}>
          <SimpleCard />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Box component="span" m={1} textAlign="center">
          <Typography className="animate__animated animate__fadeInRight" variant="h4" component="h1" gutterBottom>
        Selecciona tu <strong color="secondary" >plan de internet</strong>
      </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Basico />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Avanzado />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Savanzado />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Servicios />
        </Grid>
      </Grid>
    </>
  );
};

export default inicio;
