import React from "react";
// import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from "@material-ui/core/Grid";
import Gorilat from "../img/gorilat.png";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import CodeIcon from "@material-ui/icons/Code";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export default function SimpleContainer() {
  return (
    <Box bgcolor="primary.main">
      <Grid container>
        <Grid item xs={12}>
          <br />
          <Typography style={{ color: "#c3c3c3" }}>
            <IconButton
              href="http://gorilat.com/"
              target="_blank"
              color="secondary"
              aria-label="Gorilat"
            >
              <Avatar alt="Gorilat" src={Gorilat} />
            </IconButton>
            <strong>Created by</strong> gorilat{" "}
            <CodeIcon color="secondary" />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
