import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import WhatsApp from "../components/WhatsApp";
import Grid from '@material-ui/core/Grid';
import Links from '@material-ui/core/Link';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {
  Link
} from "react-router-dom";


const useStyles = makeStyles({
  root: {
    minWidth: "100%",
    background: "none",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard() {
  const classes = useStyles();

  return (
    <Container>
    <Card className={classes.root} elevation={0}>
    <CardContent>
        <div>
        <Grid container >
        <Grid item xs={12}>
        <br></br>
        <Typography variant="h6" gutterBottom>
        <strong>Atención a clientes</strong>
      </Typography>
        <Typography  variant="h5" gutterBottom >
        <Links href="tel:+5213447840310">
        <PhoneForwardedIcon/>
        <strong className="ColorRed" > 344-784-0310</strong>
        </Links>
      </Typography>
      <Grid item xs={12}>
        <Button href="tel:+5213447840310" variant="contained" color="secondary">
            Llamar
          </Button>
        </Grid>
        </Grid>
        </Grid>
        </div>
      </CardContent>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Contactanos ¡Ya!
        </Typography>
        <Typography variant="h5" component="h2">
          WhatsApp
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          súper ¡veloz!
        </Typography>
        <Typography variant="body2" component="p">
          <WhatsApp />
        </Typography>
      
      <CardActions>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
        <Box>
        <Links href="https://wa.me/5213441047925" target='blank'>
        Numero WhatsApp
        <br/>
        <strong className="ColoredDos" > 344-104-7925</strong>
        </Links>
        <br/>
          Es un placer servirte, no dudes en contactarnos , mandanos
          tus preguntas y contrata tu
          <Link to="/paquetes" style={{ color: 'inherit', textDecoration: 'inherit'}}>
          <Button color="secondary">Paquete </Button>
          </Link>
          </Box>
        </Typography>

        {/* <Button size="small">Learn More</Button> */}
      </CardActions>
      </CardContent>
      
    </Card>
    </Container>
  );
}
