import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';
import ClassIcon from '@material-ui/icons/Class';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import GavelIcon from '@material-ui/icons/Gavel';
import StoreIcon from '@material-ui/icons/Store';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import Chip from '@material-ui/core/Chip';
//PDF
import pdfArco from '../pdf/derechos-arco.pdf'
import pdfCarta from '../pdf/derechos.pdf';
import contrato2019 from '../pdf/189-2019.pdf';
import contrato2020 from '../pdf/368-2020.pdf';
import Contrato2021 from "../pdf/196-2021.pdf";
import Contrato2023 from "../pdf/603-2023.pdf";
/* import veinteNueve from "../pdf/189-2019.pdf"
import veinteUno from "../pdf/196-2021.pdf";
import veinteCero from "../pdf/368-2020.pdf" */
import privacidad from '../pdf/privacidad.pdf';
import comercial from '../pdf/practicas.pdf';
import amp from '../pdf/amp.pdf';
import politicas from '../pdf/codigo_politicas_de_gestion.pdf';
import ades from '../pdf/275-2023b.pdf';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

/* const mulipleWindows =()=>{
  window.open(veinteNueve);
  window.open(veinteCero);
  window.open(veinteUno);
} */

export default function AlignItemsList() {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
        <IconButton color="primary" aria-label="derechos de arco" href={pdfArco} target="_blank" rel="noopener noreferrer">
        <AccountBalanceIcon />
      </IconButton>
        </ListItemAvatar>
        <ListItemText
          primary="Derechos de arco"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                gob.mx
              </Typography>
              {" — La protección de tus datos personales es un derecho vinculado a la protección de tu privacidad. Te ofrece los medios para controlar el uso ajeno y destino de tu información personal, con el propósito de impedir su tráfico ilícito y la potencial vulneración de tu dignidad."}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
        <IconButton color="primary" aria-label="derechos de arco" href={politicas} target="_blank" rel="noopener noreferrer">
        <AccessibilityIcon />
      </IconButton>
        </ListItemAvatar>
        <ListItemText
          primary="Políticas de gestión de trafico y administración de red"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                gob.mx
              </Typography>
              {" — El objetivo del presente Código es asegurar la calidad, capacidad y velocidad del servicio de acceso a Internet, así como a preservar la integridad y seguridad de la red."}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
        <IconButton color="primary" aria-label="carta de derecho" href={pdfCarta} target="_blank" rel="noopener noreferrer">
        <DescriptionIcon />
      </IconButton>
        </ListItemAvatar>
        <ListItemText
          primary="Carta de derechos"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                ift-profeco
              </Typography>
              {" — Carta de derechos mínimos de los usuarios de los servicios públicos de telecomunicaciones."}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
        <IconButton color="primary" aria-label="contrato de adhesion" href={ades} target="_blank" rel="noopener noreferrer" >
        <ClassIcon />
      </IconButton>
        </ListItemAvatar>
        <ListItemText
          primary="Contrato de adhesión"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                gob.mx
              </Typography>
              <p>Historial de contratos:</p>
              {' — Registro de contrato de adhesión de telecomunicaciones.'}
              <Chip style={{ margin: 3,}}  label="189-2019" component="a" href={contrato2019} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
              <Chip style={{ margin: 3,}}  label="368-2020" component="a" href={contrato2020} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
              <Chip style={{ margin: 3,}}  label="196-2021" component="a" href={Contrato2021} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
              <Chip style={{ margin: 3,}}  label="275/2023" component="a" href={ades} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
              <Chip style={{ margin: 3,}}  label="603/2023" component="a" href={Contrato2023} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
        <IconButton color="primary" aria-label="contrato de adhesion" href={privacidad} target="_blank" rel="noopener noreferrer" >
        <AssignmentIndIcon/>
      </IconButton>
        </ListItemAvatar>
        <ListItemText
          primary="Aviso de privacidad"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                gob.mx
              </Typography>
              {' — De acuerdo a lo previsto en la “Constitución Política de los Estados Unidos Mexicanos y Ley Federal de Protección de Datos Personales”.'}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
        <IconButton color="primary" aria-label="contrato de adhesion" href={amp} target="_blank" rel="noopener noreferrer" >
        <GavelIcon/>
      </IconButton>
        </ListItemAvatar>
        <ListItemText
          primary="Colaboración con la justicia"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                gob.mx
              </Typography>
              {' — Nuestra colaboración con la justicia documentación en linea dando click a icono.'}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
        <IconButton color="primary" aria-label="contrato de adhesion" href={comercial} target="_blank" rel="noopener noreferrer" >
        <StoreIcon/>
      </IconButton>
        </ListItemAvatar>
        <ListItemText
          primary="Practicas comerciales"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                gob.mx
              </Typography>
              {' — El presente Código de Prácticas Com erciales es un documento que tiene como objetivo describir y ofrecer inform ación puntual a los suscriptores sobre derechos y obligaciones relacionados con el servicio de acceso a internet.'}
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  );
}
