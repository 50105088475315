import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CssBaseline from "@material-ui/core/CssBaseline";
import logo from "../img/logo.png";
import Hidden from "@material-ui/core/Hidden";
import AppNav from "../components/appNav";
import Piepagina from "../theme/piePagina";
import Footer from "../theme/footer";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Badge from "@material-ui/core/Badge";
import "animate.css";
//tipografia
import "fontsource-roboto";
// import Typography from '@material-ui/core/Typography';
//Paginas
import Inicio from "../pages/inicio";
import Paquetes from "../pages/paquetes";
import Servicios from "../pages/servicios";
import Contacto from "../pages/contacto";
import Cobertura from "../pages/cobertura";
//modo dark
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { useState } from "react";
import { blueGrey, grey, red } from "@material-ui/core/colors";
import Brightness4Icon from "@material-ui/icons/Brightness4";
//router
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
//contenedor
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
//iconos
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  affected: {
    textAlign: "right",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const App = () => {
  //AppDark/////////////////////////////////////////////////////
  const [darkMode, setDarkMode] = useState(false);
  const darkTheme = createMuiTheme({
    palette: {
      type: "dark",
      primary: {
        main: blueGrey[50],
      },
      secondary: {
        main: red[200],
      },
    },
  });

  const lightTheme = createMuiTheme({
    palette: {
      primary: {
        main: grey[800],
      },
      secondary: {
        main: red[500],
      },
    },
  });
  /////////////////////////////////////////////////////////////
  //cambio icono

  const classes = useStyles();

  return (
    <Router>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <Paper>
          <div>
            <Container maxWidth="lg">
              <div className={classes.root}>
                <CssBaseline />
                <AppBar>
                  <Toolbar>
                    <AppNav />
                    <div className={classes.title}>
                      <Hidden xsDown>
                        <Link
                          to="/"
                          className="animate__animated animate__fadeInRight"
                        >
                          <img
                            src={logo}
                            width="200"
                            alt="terabyte internet"
                            className="fondoLogo"
                          />
                        </Link>
                        <span className={classes.derecha}></span>
                      </Hidden>
                      {/* <Hidden smUp>
                  <Link to="/" className="animate__animated animate__fadeInRight">
                    <img
                      src={logo}
                      width="80%"
                      alt="terabyte internet"
                      className="fondoLogo"
                      
                    />
                    </Link>
                    <span className={classes.derecha}></span>
                  </Hidden> */}
                    </div>
                    <div>
                      <ButtonGroup
                        disableElevation
                        variant="contained"
                        color="primary"
                      >
                        
                        <Button href="https://sistema.terabytewifi.com/cliente/login" rel="noopener">
                          <Badge
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={"pagos"}
                            color="primary"
                          >
                            mi portal&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                          </Badge>
                        </Button>

                        <Link
                          to="/contacto"
                          style={{
                            color: "inherit",
                            textDecoration: "inherit",
                          }}
                        >
                          <Button variant="contained" color="secondary">
                            Contacto
                          </Button>
                        </Link>
                      </ButtonGroup>
                      {/* <Link to="/contacto" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                  <Button variant="contained" color="secondary">
                    <strong>contacto</strong>
                  </Button>
                  </Link> */}
                    </div>
                    <IconButton
                      checked={darkMode}
                      onClick={() => setDarkMode(!darkMode)}
                      aria-label="dark mode"
                    >
                      <Brightness4Icon color="secondary" />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <main className={classes.content}>
                  <div className={classes.toolbar} />
                  <Container maxWidth="xl">
                    <Box className={classes.affected}>
                      <IconButton
                        aria-label="delete"
                        href="https://www.facebook.com/terabyteinternetdedicado/"
                        target="_blank"
                      >
                        <FacebookIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        href="https://www.instagram.com/terabyte.mx/?igshid=mdy2m6y4i3lx"
                        target="_blank"
                      >
                        <InstagramIcon />
                      </IconButton>
                    </Box>
                  </Container>
                  <br />
                  <Switch>
                    <Route exact path="/">
                      <Inicio />
                    </Route>
                    <Route path="/paquetes">
                      <Paquetes />
                    </Route>
                    <Route path="/servicios">
                      <Servicios />
                    </Route>
                    <Route path="/cobertura">
                      <Cobertura />
                    </Route>
                    <Route path="/contacto">
                      <Contacto />
                    </Route>
                    {/* <Route path="/cobertura">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Rhoncus dolor purus non enim praesent elementum facilisis leo
                  vel. Risus at ultrices mi tempus imperdiet. Semper risus in
                  hendrerit gravida rutrum quisque non tellus. Convallis
                  convallis tellus id interdum velit laoreet id donec ultrices.
                  Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl
                  suscipit adipiscing bibendum est ultricies integer quis.
                  Cursus euismod quis viverra nibh cras. Metus vulputate eu
                  scelerisque felis imperdiet proin fermentum leo. Mauris
                  commodo quis imperdiet massa tincidunt. Cras tincidunt
                  lobortis feugiat vivamus at augue. At augue eget arcu dictum
                  varius duis at consectetur lorem. Velit sed ullamcorper morbi
                  tincidunt. Lorem donec massa sapien faucibus et molestie ac.
                </Route> */}
                  </Switch>
                  <br />
                  <Piepagina />
                  <Link
                    to="/contacto"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    <Button variant="contained" color="secondary">
                      <strong>contacto cliente</strong>
                    </Button>
                  </Link>
                </main>
              </div>
            </Container>
            <footer>
              <Footer />
            </footer>
          </div>
        </Paper>
      </ThemeProvider>
    </Router>
  );
};

export default App;
