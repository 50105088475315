import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Serviciosti from '../img/serviciosit.png'
import Container from '@material-ui/core/Container';
import Catalogo from '../components/acordeon'
import {
  Link
} from "react-router-dom";


const useStyles = makeStyles({
    root: {
      maxWidth: "100",
    },
    media: {
      height: 140,
    },
  });
  
  export default function MediaCard() {
    const classes = useStyles();
  
    return (
      <div className="animate__animated animate__fadeInUp">
        <Container maxWidth="md">
      <Card className={classes.root}>
          <CardMedia
            className={classes.media}
            image={Serviciosti}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Catálogo de servicios y productos
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              En Tarebyte tenemos varios productos y servicios aqui un desglose: 
            </Typography>
          </CardContent>
          <Catalogo/>
          <br/>
        <CardActions>
          <Link to="/contacto" style={{ color: 'inherit', textDecoration: 'inherit'}}>
          <Button size="small" color="primary">
            contratar
          </Button>
          </Link>
        </CardActions>
      </Card>
      </Container>
      </div>
    );
  }