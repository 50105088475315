import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
//tarjetas
import Basico from "../cards/basico";
import Avanzado from "../cards/avanzado";
import Savanzado from "../cards/savanzado";
import FibraOptica from "../cards/fibraOptica.js";
import Formatos from "../cards/Formatos.js";
import Canales from "../cards/canales.js"
//precios
import Precios from "../components/Precios";
import PreciosOc from "../components/preciosHidden";
import Hidden from "@material-ui/core/Hidden";
//PDF
import PDF from "../pdf/tv.pdf"

const paquete = () => {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            className="animate__animated animate__fadeInUp"
          >
            <FibraOptica />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid
            container
            spacing={2}
            className="animate__animated animate__fadeInUp"
          >
            <Typography variant="h6" gutterBottom color="secondary">
              <strong>Precio</strong> $400mxn*
            </Typography>
            <Avanzado />
            <Grid xs={12} lg={6} className="padingC">
              <Typography variant="h6" gutterBottom color="secondary">
                <strong>Precio</strong> $350mxn*
              </Typography>
              <Basico />
            </Grid>
            <Grid xs={12} lg={6}>
              <Typography variant="h6" gutterBottom color="secondary">
                <strong>Precio</strong> $500mxn*
              </Typography>
              <Savanzado />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <br />
          <br />
          <Hidden mdUp>
            <PreciosOc />
          </Hidden>
          <Hidden smDown>
            <Precios />
          </Hidden>
          <br />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Formatos />
          <br /> 
          <Typography variant="h3" gutterBottom color="secondary">
                <strong>Ya llego la ¡TV!</strong>
              </Typography>
          <Typography variant="h6" color="textSecondary" component="p">
          <strong> TV por $99 mxn (22 canales) y 2 TV por $129 mxn (22 canales)**</strong> 
          <br/>
          <br/>
        </Typography>
          <Canales/>
          <br/>
          <Typography variant="caption" display="block" gutterBottom>
            *Los precios cambian según tu localidad, favor de preguntar precios
            a ejecutivo de ventas o puede verlas en la tabla del inicio de esta
            PÁGINA WEB.
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            **Las reglas de instalación de TV las puede ver <a href={PDF}>aquí.</a>
          </Typography>
          
          <Button variant="contained" color="secondary" href="https://tarifas.ift.org.mx/ift_visor/" target="_blank">
          Consulta de tarifas IFT
         </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default paquete;
