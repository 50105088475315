import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import Typography from '@material-ui/core/Typography';
import Iframe from 'react-iframe';

const useStyles = makeStyles({
  root: {
    minWidth: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard() {
  const classes = useStyles();


  return (
    <div className="animate__animated animate__fadeInUp">
    <Card className={classes.root} >
      <CardContent>
        <Iframe
             url="https://www.google.com/maps/d/u/0/embed?mid=1Rucv8gFS-Lb8p-D_S8baE9atsbR1dCpn&ehbc=2E312F"
             width="100%"
             height="450"
             frameBorder ="0"
             allow="fullscreen"
        />
      </CardContent>
    </Card>
    </div>
  );
}
