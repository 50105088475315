import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import PaymentIcon from "@material-ui/icons/Payment";
import Cuentas from "../components/cuentasB";

const useStyles = makeStyles({
  root: {
    minWidth: "auto",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function OutlinedCard() {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={0}>
      <CardContent>
        <Typography variant="h5" component="h2" color="secondary">
          Formas de pago
          <PaymentIcon />
        </Typography>

        <Typography className={classes.pos} color="textSecondary">
          Puedes realizar tu pago en el establecimiento Benito Juárez 160 Benito
          Juárez 160, La Ermita, 47300 Yahualica de González Gallo, Jal se
          acepta transferencia electrónica, pago en caja depósitos, tarjeta de
          débito o credito y efectivo, una vez realizado el pago puedes enviarlo
          al correo o el WhatsApp.
        </Typography>
        <Typography variant="body2" component="p">
          Correo:{" "}
          <strong>
            <a
              href="mailto:facturacion@terabytewifi.com"
              style={{ color: "#292B2B" }}
              rel="noopener"
            >
              facturacion@terabytewifi.com
            </a>
          </strong>
          <br />
          WhatsApp:{" "}
          <strong>
            <a
             style={{ color: "#292B2B" }}
              href="https://wa.me/5213441047925" 
              target='blank'
              rel="noopener"
            >
              3441047925
            </a>
          </strong>
        </Typography>
        <br/>
        <Cuentas/>
      </CardContent>
    </Card>
  );
}

/* href="https://wa.me/5213441047925" target='blank' */
