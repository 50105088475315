import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import sieteCinco from '../formatos/397475.pdf';
import sieteSeis from '../formatos/397476.pdf';
import sieteSiete from '../formatos/397477.pdf';
import sieteOcho from '../formatos/397478.pdf';
import sieteNueve from '../formatos/397479.pdf';
import ocho from '../formatos/397480.pdf';
import ochoUno from '../formatos/397481.pdf';
import ochoDos from '../formatos/397482.pdf';
import ochoTres from '../formatos/397483.pdf';
import ochoCuatro from '../formatos/397484.pdf';
import ochoCinco from '../formatos/397485.pdf';
import ochoSeis from '../formatos/397486.pdf';
import ochoSiete from '../formatos/397487.pdf';
import ochoOcho from '../formatos/397488.pdf';
import ochoNueve from '../formatos/397489.pdf';
import nueve from '../formatos/397490.pdf';
import nueveUno from '../formatos/397491.pdf';
import nueveDos from '../formatos/397492.pdf';
import nueveTres from '../formatos/397493.pdf';
import nueveCuatro from '../formatos/397494.pdf';
import nueveCinco from '../formatos/397495.pdf';
import nueveSeis from '../formatos/397496.pdf';
import nueveSiete from '../formatos/397497.pdf';
import nueveOcho from '../formatos/397498.pdf';
import nueveNueve from '../formatos/397499.pdf';
import cinco from '../formatos/397500.pdf';
import cincoUno from '../formatos/397501.pdf';
import cincoDos from '../formatos/397502.pdf';
import cincoTres from '../formatos/397503.pdf';
import cincoCuatro from '../formatos/397504.pdf';
import cincoCinco from '../formatos/397505.pdf';
import cincoSeis from '../formatos/397506.pdf';
import cincoSiete from '../formatos/397507.pdf';
import cincodOcho from '../formatos/454518.pdf';
import cincodNueve from '../formatos/454519.pdf';
import cincovUno from '../formatos/454521.pdf';
import cincovTres from '../formatos/454523.pdf';
import cincovCuatro from '../formatos/454524.pdf';
import cincovSeis from '../formatos/454526.pdf';
import noventaCinco from '../formatos/831595.pdf';
import ochentaSeis from '../formatos/831586.pdf';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));


export default function DetailedAccordion() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading} color="secondary">Formatos simplificados</Typography>
          </div>
          
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          
          <div className={clsx(classes.helper)}>
          <p>Descargas en PDF.</p>
          <Grid container>
          <Chip style={{ margin: 3,}}  label="397475" component="a" href={sieteCinco} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397476" component="a" href={sieteSeis} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397477" component="a" href={sieteSiete} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397478" component="a" href={sieteOcho} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397479" component="a" href={sieteNueve} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397480" component="a" href={ocho} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397481" component="a" href={ochoUno} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397482" component="a" href={ochoDos} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397483" component="a" href={ochoTres} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397484" component="a" href={ochoCuatro} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397485" component="a" href={ochoCinco} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397486" component="a" href={ochoSeis} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397487" component="a" href={ochoSiete} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397488" component="a" href={ochoOcho} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397489" component="a" href={ochoNueve} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397490" component="a" href={nueve} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397491" component="a" href={nueveUno} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397492" component="a" href={nueveDos} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397493" component="a" href={nueveTres} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397494" component="a" href={nueveCuatro} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397495" component="a" href={nueveCinco} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397496" component="a" href={nueveSeis} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397497" component="a" href={nueveSiete} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397498" component="a" href={nueveOcho} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397499" component="a" href={nueveNueve} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397500" component="a" href={cinco} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397501" component="a" href={cincoUno} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397502" component="a" href={cincoDos} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397503" component="a" href={cincoTres} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397504" component="a" href={cincoCuatro} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397505" component="a" href={cincoCinco} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397506" component="a" href={cincoSeis} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="397507" component="a" href={cincoSiete} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="454518" component="a" href={cincodOcho} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="454519" component="a" href={cincodNueve} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="454521" component="a" href={cincovUno} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="454523" component="a" href={cincovTres} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="454524" component="a" href={cincovCuatro} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="454526" component="a" href={cincovSeis} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="831595" component="a" href={noventaCinco} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          <Chip style={{ margin: 3,}}  label="831586" component="a" href={ochentaSeis} clickable variant="outlined" target='blank' rel="noreferrer noopener"/>
          </Grid>
            
          </div>
        </AccordionDetails>
        <Divider />
      </Accordion>
    </div>
  );
}
