import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from "clsx";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import RouterIcon from "@material-ui/icons/Router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledAccordions() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    {/* SAN YAHUALICA PRECIO */}
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.secondaryHeading}>Yahualica</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
              Yahualica, Nochistlán y Mezcala
            </Typography>
            <br/>
            <br/>
            <Chip
                avatar={<Avatar>4M</Avatar>}
                size="small"
                label="Básico $250"
              />
              <br />
              
              <Chip
                avatar={<Avatar>6M</Avatar>}
                label="Avanzado $300"
                color="secondary"
              />
              <br />
           
              <Chip
                avatar={<Avatar>8M</Avatar>}
                size="small"
                label="S.Avanzado $500"
              />
              <hr/>
              <RouterIcon />
            <Typography variant="caption">
              Precio de instalación $1,000 mxn
              <br />
              {/* <a href="#secondary-heading-and-columns" className={classes.link}>
                Learn more
              </a> */}
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* IXTLAHUACAN PRECIO */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.secondaryHeading}>Ixtlahuacán del río</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
            Ixtlahuacán del río y Cuquio
            </Typography>
            <br/>
            <br/>
            <Chip
                avatar={<Avatar>2M</Avatar>}
                size="small"
                label="Básico $300"
              />
              <br />
              
              <Chip
                avatar={<Avatar>3M</Avatar>}
                label="Avanzado $350"
                color="secondary"
              />
              <br />
           
              <Chip
                avatar={<Avatar>5M</Avatar>}
                size="small"
                label="S.Avanzado $500"
              />
              <hr/>
              <RouterIcon />
            <Typography variant="caption">
              Precio de instalación $1,000 mxn
              <br />
              {/* <a href="#secondary-heading-and-columns" className={classes.link}>
                Learn more
              </a> */}
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>

       {/* SAN ANTONIO PRECIO */}
       <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.secondaryHeading}>San Antonio</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
            San Antonio de los Vázquez, Tlacotán y Trejos
            </Typography>
            <br/>
            <br/>
            <Chip
                avatar={<Avatar>2M</Avatar>}
                size="small"
                label="Básico $350"
              />
              <br />
              
              <Chip
                avatar={<Avatar>3M</Avatar>}
                label="Avanzado 400"
                color="secondary"
              />
              <br />
           
              <Chip
                avatar={<Avatar>5M</Avatar>}
                size="small"
                label="S.Avanzado $500"
              />
              <hr/>
              <RouterIcon />
            <Typography variant="caption">
              Precio de instalación $1,000 mxn
              <br />
              {/* <a href="#secondary-heading-and-columns" className={classes.link}>
                Learn more
              </a> */}
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>

      {/* JUANACATLAN PRECIO */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.secondaryHeading}>Juanacatlan</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
            Villas Andalucía(Juanacatlán)
            </Typography>
            <br/>
            <br/>
            <Chip
                avatar={<Avatar>5M</Avatar>}
                size="small"
                label="Básico $300"
              />
              <br />
              
              <Chip
                avatar={<Avatar>20M</Avatar>}
                label="Avanzado $400"
                color="secondary"
              />
              <br />
           
              <Chip
                avatar={<Avatar>50M</Avatar>}
                size="small"
                label="S.Avanzado $600"
              />
              <hr/>
              <RouterIcon />
            <Typography variant="caption">
              Precio de instalación $390 mxn
              <br />
              {/* <a href="#secondary-heading-and-columns" className={classes.link}>
                Learn more
              </a> */}
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
 
      {/* LA JARRILLA PRECIO */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.secondaryHeading}>La Jarrilla</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
            Rancherias y La Jarilla
            </Typography>
            <br/>
            <br/>
            <Chip
                avatar={<Avatar>2M</Avatar>}
                size="small"
                label="Básico $350"
              />
              <br />
              
              <Chip
                avatar={<Avatar>3M</Avatar>}
                label="Avanzado $400"
                color="secondary"
              />
              <br />
           
              <Chip
                avatar={<Avatar>5M</Avatar>}
                size="small"
                label="S.Avanzado $500"
              />
              <hr/>
              <RouterIcon />
            <Typography variant="caption">
              Precio de instalación $1000 mxn
              <br />
              {/* <a href="#secondary-heading-and-columns" className={classes.link}>
                Learn more
              </a> */}
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>

       {/* LA MANALISCO ECT.. PRECIO */}
       <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.secondaryHeading}>Manalisco, Huisquilco ...</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
            Manalisco, Huisquilco, San Isidro, San Felipe, cañada de isla, El molino, Las Huertas,
              Nangue, El Pochote, Palmarejo, Jesus Maria, Acasico.
            </Typography>
            <br/>
            <br/>
            <Chip
                avatar={<Avatar>2M</Avatar>}
                size="small"
                label="Básico $250"
              />
              <br />
              
              <Chip
                avatar={<Avatar>3M</Avatar>}
                label="Avanzado $300"
                color="secondary"
              />
              <br />
           
              <Chip
                avatar={<Avatar>5M</Avatar>}
                size="small"
                label="S.Avanzado $500"
              />
              <hr/>
              <RouterIcon />
            <Typography variant="caption">
              Precio de instalación $1000 mxn
              <br />
              {/* <a href="#secondary-heading-and-columns" className={classes.link}>
                Learn more
              </a> */}
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
