import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
// import Button from '@material-ui/core/Button';
import Divider from "@material-ui/core/Divider";
import NearMeIcon from "@material-ui/icons/NearMe";
import RouterIcon from "@material-ui/icons/Router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default function DetailedAccordion() {
  const classes = useStyles();

  return (
    <div className="animate__animated animate__fadeInUp">
    <div className={classes.root}>
      {/* SAN YAHUALICA PRECIO */}
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Yahualica </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              Precios
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
              Yahualica, Nochistlán y Mezcala
            </Typography>
          </div>
          <div className={classes.column}>
            <ul>
              <Chip
                avatar={<Avatar>4M</Avatar>}
                size="small"
                label="Básico $250"
              />
              <br />
              <br />
              <Chip
                avatar={<Avatar>6M</Avatar>}
                label="Avanzado $300"
                color="secondary"
              />
              <br />
              <br />
              <Chip
                avatar={<Avatar>8M</Avatar>}
                size="small"
                label="S.Avanzado $500"
              />
            </ul>
          </div>
          <div className={clsx(classes.column, classes.helper)}>
            <RouterIcon />
            <Typography variant="caption">
              Precio de instalaciones $1,000 mxn
              <br />
              {/* <a href="#secondary-heading-and-columns" className={classes.link}>
                Learn more
              </a> */}
            </Typography>
          </div>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <NearMeIcon color="disabled" />
          <Typography variant="subtitle2" gutterBottom color="secondary">
            Se hacen instalaciones en <strong>rancherias cercanas</strong>
          </Typography>
        </AccordionActions>
      </Accordion>

      {/* SAN IXTLAHUACAN DEL RIO PRECIO */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Ixtlahuacán del río </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              Precios
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
              Ixtlahuacán del rìo y Cuquio
            </Typography>
          </div>
          <div className={classes.column}>
            <ul>
              <Chip
                avatar={<Avatar>2M</Avatar>}
                size="small"
                label="Básico $300"
              />
              <br />
              <br />
              <Chip
                avatar={<Avatar>3M</Avatar>}
                label="Avanzado $350"
                color="secondary"
              />
              <br />
              <br />
              <Chip
                avatar={<Avatar>5M</Avatar>}
                size="small"
                label="S.Avanzado $500"
              />
            </ul>
          </div>
          <div className={clsx(classes.column, classes.helper)}>
            <RouterIcon />
            <Typography variant="caption">
              Precio de instalación $1,000 mxn
              <br />
              {/* <a href="#secondary-heading-and-columns" className={classes.link}>
                Learn more
              </a> */}
            </Typography>
          </div>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <NearMeIcon color="disabled" />
          <Typography variant="subtitle2" gutterBottom color="secondary">
            Se hacen instalaciones en <strong>rancherias cercanas</strong>
          </Typography>
        </AccordionActions>
      </Accordion>

      {/* SAN ANTONIO PRECIO */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>San Antonio </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              Precios
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
              San Antonio de los Vázquez, Tlacotán y Trejos
            </Typography>
          </div>
          <div className={classes.column}>
            <ul>
              <Chip
                avatar={<Avatar>2M</Avatar>}
                size="small"
                label="Básico $350"
              />
              <br />
              <br />
              <Chip
                avatar={<Avatar>3M</Avatar>}
                label="Avanzado $400"
                color="secondary"
              />
              <br />
              <br />
              <Chip
                avatar={<Avatar>5M</Avatar>}
                size="small"
                label="S.Avanzado $500"
              />
            </ul>
          </div>
          <div className={clsx(classes.column, classes.helper)}>
            <RouterIcon />
            <Typography variant="caption">
              Precio de instalación $1,000 mxn
              <br />
              {/* <a href="#secondary-heading-and-columns" className={classes.link}>
                Learn more
              </a> */}
            </Typography>
          </div>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <NearMeIcon color="disabled" />
          <Typography variant="subtitle2" gutterBottom color="secondary">
            Se hacen instalaciones en <strong>rancherias cercanas</strong>
          </Typography>
        </AccordionActions>
      </Accordion>

      {/* JUANACATLAN PRECIO */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Juanacatlán </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              Precios
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
              Villas Andalucía(Juanacatlán)
            </Typography>
          </div>
          <div className={classes.column}>
            <ul>
              <Chip
                avatar={<Avatar>5M</Avatar>}
                size="small"
                label="Básico $300"
              />
              <br />
              <br />
              <Chip
                avatar={<Avatar>20M</Avatar>}
                label="Avanzado $400"
                color="secondary"
              />
              <br />
              <br />
              <Chip
                avatar={<Avatar>50</Avatar>}
                size="small"
                label="S.Avanzado $600"
              />
            </ul>
          </div>
          <div className={clsx(classes.column, classes.helper)}>
            <RouterIcon />
            <Typography variant="caption">
              Precio de instalación $390 mxn
              <br />
              {/* <a href="#secondary-heading-and-columns" className={classes.link}>
                Learn more
              </a> */}
            </Typography>
          </div>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <NearMeIcon color="disabled" />
          <Typography variant="subtitle2" gutterBottom color="secondary">
            Se hacen instalaciones en <strong>rancherias cercanas</strong>
          </Typography>
        </AccordionActions>
      </Accordion>

      {/* LA JARRILLA PRECIO */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>La Jarrilla </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              Precios
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">Rancherias y La Jarilla</Typography>
          </div>
          <div className={classes.column}>
            <ul>
              <Chip
                avatar={<Avatar>2M</Avatar>}
                size="small"
                label="Básico $350"
              />
              <br />
              <br />
              <Chip
                avatar={<Avatar>3M</Avatar>}
                label="Avanzado $400"
                color="secondary"
              />
              <br />
              <br />
              <Chip
                avatar={<Avatar>5M</Avatar>}
                size="small"
                label="S.Avanzado $500"
              />
            </ul>
          </div>
          <div className={clsx(classes.column, classes.helper)}>
            <RouterIcon />
            <Typography variant="caption">
              Precio de instalación $1000 mxn
              <br />
              {/* <a href="#secondary-heading-and-columns" className={classes.link}>
                Learn more
              </a> */}
            </Typography>
          </div>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <NearMeIcon color="disabled" />
          <Typography variant="subtitle2" gutterBottom color="secondary">
            Se hacen instalaciones en <strong>rancherias cercanas</strong>
          </Typography>
        </AccordionActions>
      </Accordion>

               {/* MALANALISCO, HUISQUILCO, SAN ISIDRO, ETC..PRECIO */}
               <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Manalisco, Huisquilco ... </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              Precios
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
              Manalisco, Huisquilco, San Isidro, San Felipe, cañada de isla, El molino, Las Huertas,
              Nangue, El Pochote, Palmarejo, Jesus Maria, Acasico.
            </Typography>
          </div>
          <div className={classes.column}>
            <ul>
              <Chip
                avatar={<Avatar>2M</Avatar>}
                size="small"
                label="Básico $250"
              />
              <br />
              <br />
              <Chip
                avatar={<Avatar>3M</Avatar>}
                label="Avanzado $300"
                color="secondary"
              />
              <br />
              <br />
              <Chip
                avatar={<Avatar>5M</Avatar>}
                size="small"
                label="S.Avanzado $500"
              />
            </ul>
          </div>
          <div className={clsx(classes.column, classes.helper)}>
            <RouterIcon />
            <Typography variant="caption">
              Precio de instalación $1,000 mxn
              <br />
              {/* <a href="#secondary-heading-and-columns" className={classes.link}>
                Learn more
              </a> */}
            </Typography>
          </div>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <NearMeIcon color="disabled" />
          <Typography variant="subtitle2" gutterBottom color="secondary">
            Se hacen instalaciones en <strong>rancherias cercanas</strong>
          </Typography>
        </AccordionActions>
      </Accordion>
    </div>
    </div>
  );
}
