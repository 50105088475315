import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Hidden from '@material-ui/core/Hidden';
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
// import Button from '@material-ui/core/Button';
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default function DetailedAccordion() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hidden smDown>
      {/* SAN YAHUALICA PRECIO */}
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Yahualica </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              <strong className="colorprecio">Precios Terafibra</strong>
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
              Yahualica, Ixtlahuacán del Rio y Cuquio.
            </Typography>
          </div>
          <div className={classes.column}>
            <ul>
              <Chip
                avatar={<Avatar>10M</Avatar>}
                size="medium"
                label="Básico $300"
              />
              <br />
              <br />
              <Chip
                avatar={<Avatar>20M</Avatar>}
                label="Avanzado $400"
                color="secondary"
              />
              <br />
              <br />
              <Chip
                avatar={<Avatar>50M</Avatar>}
                size="medium"
                label="S.Avanzado $600"
              />
            </ul>
          </div>
        </AccordionDetails>
        <Divider />
      </Accordion>

      {/* SAN IXTLAHUACAN DEL RIO PRECIO */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>
              {" "}
              San Antonio de los Vázquez
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
              Trejos,tlacotan y mascuala.
            </Typography>
          </div>
          <div className={classes.column}>
            <ul>
              <Chip
                avatar={<Avatar>10M</Avatar>}
                size="medium"
                label="Básico $400"
              />
              <br />
              <br />
              <Chip
                avatar={<Avatar>20M</Avatar>}
                label="Avanzado $500"
                color="secondary"
              />
              <br />
              <br />
              <Chip
                avatar={<Avatar>50M</Avatar>}
                size="medium"
                label="S.Avanzado $700"
              />
            </ul>
          </div>
        </AccordionDetails>
        <Divider />
      </Accordion>
      </Hidden>


      <Hidden mdUp>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.secondaryHeading}>Yahualica</Typography>
        </AccordionSummary>
        <AccordionDetails>
          
        <div className={clsx(classes.column, classes.helper)}>
        <Typography className={classes.secondaryHeading}>
              <strong className="colorprecio">Precios Terafibra</strong>
            </Typography>
            <Typography variant="caption">
            Yahualica, Ixtlahuacán del Rio y Cuquio.
            </Typography>
            <br/>
            <br/>
            <Chip
                avatar={<Avatar>10M</Avatar>}
                size="medium"
                label="Básico $300"
              />
              <br />
              
              <Chip
                avatar={<Avatar>20M</Avatar>}
                size="medium"
                label="Avanzado $400"
                color="secondary"
              />
              <br />
           
              <Chip
                avatar={<Avatar>50M</Avatar>}
                size="medium"
                label="S.Avanzado $600"
              />
              <hr/>
              
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.secondaryHeading}>San Antonio de los Vázquez</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
            Trejos,tlacotan y mascuala.
            </Typography>
            <br/>
            <br/>
            <Chip
                avatar={<Avatar>10M</Avatar>}
                size="medium"
                label="Básico $400"
              />
              <br />
              
              <Chip
                avatar={<Avatar>20M</Avatar>}
                size="medium"
                label="Avanzado $500"
                color="secondary"
              />
              <br />
              <Chip
                avatar={<Avatar>50M</Avatar>}
                size="medium"
                label="S.Avanzado $700"
              />
              <hr/>
          </div>
        </AccordionDetails>
      </Accordion>
      </Hidden>
    </div>
  );
}
