/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import Grid from "@material-ui/core/Grid";
import FormasPago from "../cards/pagos";

// Tarjetas
import Contacto from "../cards/contacto";
import Mapa from "../cards/mapa";
import TarjetaContacto from "../cards/tarjetaDos";
import Horarios from "../cards/horarios";

export default function contacto() {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs="12" sm="4">
          <TarjetaContacto />
          <br/>
          <br/>
          <Horarios />
        </Grid>
        <Grid item xs="12" sm="8">
          <Contacto />
          
          <br/>
          <div className="aviso">
          <p style={{ color: "#A5A8A9"}}>
            Procedimiento de Atención a Clientes Procedimiento para la atención
            de Dudas, aclaraciones, quejas y reclamaciones.
          </p>
          <p style={{ color: "#A5A8A9" }}>
            a) Los Usuarios reciben atención de consultas, sugerencias,
            disputas, quejas y/o fallas de manera gratuita y acceden al centro
            de atención telefónica marcando el número telefónico 3447840310.
            Whatsapp 3441047925.
            <br />
            b) Una vez que se ha comunicado vía telefónica o por mensaje de
            WhatsApp, puede solucionar su duda, consultas, sugerencias,
            disputas, quejas y/o fallas mediante un ejecutivo que atenderá su
            petición.
            <br />
            c) El centro de atención telefónica o a través de WhatsApp, cuenta
            con la información que se le proporciona al usuario en cuanto a
            zonas de cobertura, descripción de servicios, equipos y planes,
            Información de tarifas, Información de la facturación.
            <br />
            d) El Centro de atención atienden las siguientes solicitudes:
            <br />
            Información General: Este servicio provee información sobre
            paquetes, tarifas, servicios y actualización de datos, consultas,
            sugerencias y en su caso el inicio de procesamiento de disputas y
            quejas Servicio Técnico: Este servicio se provee las 24 horas de los
            365 días del año, en donde se procesan fallas del servicio del
            suscriptor y/o cliente. Robo y extravío: Este servicio se provee las
            24 horas de los 365 días del año, en donde se procesan todas
            aquellas peticiones de los usuarios que sufren un percance y
            necesitan reportar su equipo como robado o extraviado. También se
            provee la información necesaria para proceder con el reemplazo, y se
            genera un número de reporte que ampara la transacción de bloqueo del
            equipo. Información de Cobranza: Se proporciona información para
            realizar sus pagos, así como llegar a convenios de pago debido al
            atraso u omisión de estos. Facturación: Aquí, se proporciona toda la
            información concerniente a la factura, fechas de corte, fechas de
            pago, detalle de consumos, descripción del contenido del paquete o
            servicio que actualmente tiene el usuario, información de cómo y
            dónde hacer sus pagos, así como aclaraciones de pagos no procesados
            por los bancos. Bonificaciones: Se proporciona toda la información
            concerniente a Bonificaciones, por causas directamente imputables a
            Terabyte, ya sea porque el Servicio no se prestó, proporcionó, o no
            se suministró en la forma y términos convenidos, contratados,
            ofrecidos o publicitados, así como con base en los estándares de
            calidad contratados o establecidos conforme a las disposiciones
            legales vigentes. Las bonificaciones, una vez que procedan, se
            llevarán a cabo conforme al procedimiento establecido en el Contrato
            de Suscriptor.
            <br />
          </p>
          </div>
          
        </Grid>
        <Grid item xs="12" sm="12">
        <FormasPago/>
          <Mapa />
          <br/>
          
        </Grid>
      </Grid>
    </div>
  );
}
