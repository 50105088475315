import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
// Tablas
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledAccordions() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading} color="secondary">Canales de TV</Typography>
          <Typography className={classes.secondaryHeading}>Terabyte TV</Typography>
        </AccordionSummary>
        <AccordionDetails>

        <Grid container item xs={6}>
          
         <ListItem button>
        <ListItemIcon>
          Can. 1
        </ListItemIcon>
        <ListItemText primary="Las estrellas" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 2
        </ListItemIcon>
        <ListItemText primary="Foro TV" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 3
        </ListItemIcon>
        <ListItemText primary="Canal 5" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 4
        </ListItemIcon>
        <ListItemText primary="Nu9ve" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 5
        </ListItemIcon>
        <ListItemText primary="Las Estrellas Delay -1 hr." />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 6
        </ListItemIcon>
        <ListItemText primary="Las Estrellas Delay -2 hr." />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 7
        </ListItemIcon>
        <ListItemText primary="Golden" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 8
        </ListItemIcon>
        <ListItemText primary="Golden EDGE" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 9
        </ListItemIcon>
        <ListItemText primary="De Película" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 10
        </ListItemIcon>
        <ListItemText primary="Unicable" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 11
        </ListItemIcon>
        <ListItemText primary="BitMe" />
      </ListItem>
      
        </Grid>

        <Grid item xs={6}>
        
        <ListItem button>
        <ListItemIcon>
          Can. 12
        </ListItemIcon>
        <ListItemText primary="TL Novelas" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 13
        </ListItemIcon>
        <ListItemText primary="Distrito Comedia" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 14
        </ListItemIcon>
        <ListItemText primary="Telehit" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 15
        </ListItemIcon>
        <ListItemText primary="Telehit Urbano" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 16
        </ListItemIcon>
        <ListItemText primary="Bandamax" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 17
        </ListItemIcon>
        <ListItemText primary="Adrenalina Sports Network" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 18
        </ListItemIcon>
        <ListItemText primary="TUDN" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 19
        </ListItemIcon>
        <ListItemText primary="Golden Plus" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 20
        </ListItemIcon>
        <ListItemText primary="De Película Plus" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 21
        </ListItemIcon>
        <ListItemText primary="Telehit Plus" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          Can. 22
        </ListItemIcon>
        <ListItemText primary="Golden MPX" />
      </ListItem>
        </Grid>
        </AccordionDetails>
        
      </Accordion>
    </div>
  );
}
