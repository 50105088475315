/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import Grid from "@material-ui/core/Grid";

// Tarjetas

import MapaCobertura from "../cards/mapaCobertura";
/* import Horarios from "../cards/horarios"; */

export default function contacto() {
  return (
    <div>
      <Grid container spacing={2}>
        {/* <Grid item xs="12" sm="4">
          <Horarios />
        </Grid> */}
        
        <Grid item xs="12" sm="12">
          <MapaCobertura />
          <br/>
          <div className="aviso">
          <p style={{ color: "#A5A8A9"}}>
          "La Prestación del servicio dentro de la Cobertura del Servicio está sujeta a la factibilidad técnica, si quiere preguntar por alguna zona de cobertura en especial, no dude en escribir o llamar". Telefono:3447840310. WhatsApp 3441047925 Horario de Atención: Lunes a viernes de: 9am a 2pm : 4pm a7 pm Sábado de: 9am a 2pm"
          </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
