import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function OutlinedCard() {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={0}>
      <CardContent>
        <Typography variant="h5" component="h2" color="secondary">
          Horarios
          <AccessTimeIcon/>
        </Typography>
        
        <Typography className={classes.pos} color="textSecondary">
          Intervalo
        </Typography>
        <Typography variant="body2" component="p">
          Lunes a viernes de: <strong>9am a 2pm : 4pm a7 pm</strong>
          <br />
          Sabado de: <strong>9am a 2pm</strong>
        </Typography>
      </CardContent>
    </Card>
  );
}
