import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import terabyteWifi from "../img/velozok.png";
import LinearScaleIcon from "@material-ui/icons/LinearScale";

import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    maxWidth: `100%`,
  },
});

export default function ImgMediaCard() {
  const classes = useStyles();

  return (
    <div className="animate__animated animate__fadeInUp">
      <Card className={classes.root}>
        <CardActionArea>
          <Link
            to="/paquetes"
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            <CardMedia
              component="img"
              alt="Internet terabyte"
              height="250"
              image={terabyteWifi}
              title="internet yahualica"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                ¡Llego la <LinearScaleIcon fontSize="large" color="secondary" />
                <strong className="ColorRed">fibra óptica</strong>
                <LinearScaleIcon fontSize="large" color="secondary" />
                a tu hogar!
              </Typography>
                
              <Typography variant="body2" color="textSecondary" component="p">
                Tenemos varias opciones y servicios .
              </Typography>
            </CardContent>
          </Link>
        </CardActionArea>
        <CardActions>
          <Link
            to="/paquetes"
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            <Button variant="contained" color="secondary">
              Ver paquetes
            </Button>
          </Link>
        </CardActions>
      </Card>
    </div>
  );
}
