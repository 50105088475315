import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import FibraOPtica from '../img/velozok.png'
import Grid from '@material-ui/core/Grid';
import FibraPre from '../components/PreciosFibra.js'

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
  },
  media: {
    height: 450,
  },
});

export default function MediaCard() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
      <CardMedia
          className={classes.media}
          image={FibraOPtica}
          title="Contemplative Reptile"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
      <CardContent>
          <Typography gutterBottom variant="h4" component="h1">
            TERAFIBRA <span className="ColorRed" > <strong>¡50 Mbps!</strong> </span>
          </Typography>
          <FibraPre/>
        </CardContent>
      </Grid>
      </Grid>
    </Card>
  );
}
