import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";  

//video
import ReactPlayer from 'react-player'
import Video from '../img/videoterabyte.mp4'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard() {
  const classes = useStyles();
  

  return (
    <div>
    <Card className={classes.root} >
      <CardContent>
        <Typography variant="h5" component="h2">
          ¡Visitanos!
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Te esperamos con ¡gusto!
        </Typography>
        <ReactPlayer
            url={Video}
            alt="internet en yahualica"
            width="100%"
            height="100%"
            controls
            volume="0.1"
            

        />
        {/* <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="250"
          image={Local}
          title="Contemplative Reptile"
        /> */}
      </CardContent>
      <CardActions>
      </CardActions>
    </Card>
    </div>
  );
}
