import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import StyleIcon from '@material-ui/icons/Style';
import BuildIcon from '@material-ui/icons/Build';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
// import MapIcon from '@material-ui/icons/Map';
import AppsIcon from '@material-ui/icons/Apps';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
//router
import {
  Link
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

// function ListItemLink(props) {
//   return <ListItem button component="a" {...props} />;
// }

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function SimpleList() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
      <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit'}}>
       <ListItem button>
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText primary="Inicio" />
        </ListItem>
        </Link>
       <Link to="/paquetes" style={{ color: 'inherit', textDecoration: 'inherit'}}>
       <ListItem button>
          <ListItemIcon>
            <StyleIcon />
          </ListItemIcon>
          <ListItemText primary="Paquetes" />
        </ListItem>
        </Link>
        <Link to="/servicios" style={{ color: 'inherit', textDecoration: 'inherit'}}>
        <ListItem button>
          <ListItemIcon>
            <BuildIcon />
          </ListItemIcon>
          <ListItemText primary="Servicios" />
        </ListItem>
        </Link>
        <Link to="/cobertura" style={{ color: 'inherit', textDecoration: 'inherit'}}>
        <ListItem button>
          <ListItemIcon>
            <GpsFixedIcon />
          </ListItemIcon>
          <ListItemText primary="Cobertura" />
        </ListItem>
        </Link>
        <Link to="contacto" style={{ color: 'inherit', textDecoration: 'inherit'}}>
        <ListItem button>
          <ListItemIcon>
            <HeadsetMicIcon />
          </ListItemIcon>
          <ListItemText primary="Contacto" />
        </ListItem>
        </Link>
      </List>
      <Divider />
      <List component="nav" aria-label="secondary mailbox folders">
      {/* <Link style={{ color: 'inherit', textDecoration: 'inherit'}}> */}
      <ListItemLink href="https://terabytewifi.com/app/">
          <ListItemIcon >
            <LockOpenIcon  />
          </ListItemIcon>
          <ListItemText primary="Login" />
          </ListItemLink>
        {/* </Link> */}
      </List>
    </div>
  );
}
